@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.body-font {}


.boxes {
    Width: 270px;
    Height: auto;
}

h3.title-font {
    font-family: Inter;
    font-size: 60px;
    font-weight: 600;
    line-height: 72.61px;
    text-align: left;
}

.para-text {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    width: 598px;
}

.box-para {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}

h2.form-font {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    line-height: 72.61px;
    /* text-align: left; */
}

.toggle-bg {
    background-color: #0E2D41;
}

.toggle-bg:hover {
    background-color: #0E2D41;
}


.form-section p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.05px;
    /* text-align: left; */
}

button.Post-btn {
    width: 185px;
    height: 50px;
    border-radius: 8px;
    background: #0E2D41;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.05px;
    letter-spacing: 0.03em;
    text-align: center;
}

.Update-section h3 {
    font-family: Inter;
    font-size: 60px;
    font-weight: 700;
    line-height: 72.61px;
    text-align: center;
}

.Update-section h6 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    width: 286px;
    margin-top: 8px;
}

.Update-section p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.image-text h6 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
}

.image-text span {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

/* .update-height{
    height:24rem;
} */

img.image1 {
    height: 100%;
}

.boxsection,
.update-height {
    background-color: #F1F1F1;
}

.globesection h3 {
    font-family: Poppins;
    font-size: 52px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
}

.globesection p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}

.boxs-section {
    background: #F1F1F1;
}

.banner-section {
    background: url("../../Assets/Images/bannerbg.png") 35% 42%;
    background-repeat: no-repeat;
}

.box1 {
    background: url("../../Assets/Images/circle.png") 94% 33%;
    background-repeat: no-repeat;
}

.box2 {
    background: url("../../Assets/Images/circle2.webp") 36% 0%;
    background-repeat: no-repeat;

}

.Update-section .image1,
.Update-section .image3,
.Update-section .image4,
.Update-section .image5,
.Update-section .image6 {
    width: 100%;
    object-fit: cover;
}

.logo-main {
    width: auto;
    height: 55px;
}

ul.top-nav {
    display: flex;
    gap: 65px;
}

ul.top-nav a {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #0E2D41;
}

ul.top-nav a:hover {
    color: #f7404d;
}

.nav-login-btn {
    height: 60px;
    line-height: 60px;
    padding: 0 40px;
    border-radius: 8px;
    background: #0E2D41;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;

}

h1.banner-style {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    padding: 70px 0;
    background-image: linear-gradient(to bottom, #ffefba, #ffffff);
}

.about-us-page p {
    padding: 5px 0 20px;
}

/* Mobile Devices*/

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    .box1,
    .box2,
    .boxs-section,
    .form-section,
    .box-row {
        display: flex;
        flex-direction: column;
    }

    .login-btn {
        display: none;
    }

    .banner-section,
    .box1,
    .box2 {
        background: none;
        background-repeat: no-repeat;
    }

    h1.title-font {
        font-size: 30px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0.01em;
        color: #0E2D41;
    }

    h3.title-font {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 65px;
        text-align: center;
        ;
    }

    .para-text {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        width: fit-content;
    }

    h2.form-font {
        font-family: Inter;
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        text-align: left;
    }

    .form-section,
    .Update-section,
    .globeimage,
    .boxs-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .form-section p {
        font-size: 18px;
        margin-bottom: 2rem;
    }

    button.Post-btn {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Update-section h3 {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
    }

    .Update-section h6 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        width: 286px;
        margin-top: 0px;
    }

    .Update-section p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-top: 8px;
    }

    .ImageSection {
        margin: 1rem;
    }

    .globesection h3 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 700;
        line-height: 41px;
        text-align: center;
    }

    .globesection p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }

    .globesection ul {
        justify-content: center;
    }

    .star,
    .arrow {
        margin: auto;
    }

    .boxes {
        width: auto;
    }

    .box-one,
    .box-two,
    .box-three,
    .box-four,
    .box-five,
    .box-six {
        margin-top: 3rem;
    }

    .footer-bg li,
    .footer-bg a {
        font-size: 12px;
    }
}

/* (767x1024) */
@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {

    .banner-section,
    .box1,
    .box2 {
        background: none;
    }

    .box-one,
    .box-two,
    .box-three,
    .box-four,
    .box-five,
    .box-six {
        margin-top: 3rem;
    }

    .boxs-section,
    .form-section,
    .box-row {
        display: flex;
        flex-direction: column;
    }

    .form-section .md\:w-1\/2,
    .globesection .md\:w-1\/2,
    .box-row .md\:w-1\/2 {
        width: 100%;
    }

    h2.form-font {
        font-size: 48px;
    }

    button.Post-btn {
        margin-top: 1rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-section p {
        margin-bottom: 2rem;
    }

    .Update-section .md\:grid-cols-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .form-section,
    .Update-section,
    .boxs-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .globeimage {
        flex-direction: column;
    }

    h3.title-font {
        font-size: 51px;
        padding-bottom: 1rem;
        text-align: center;

    }

    .para-text {
        font-size: 24px;
        text-align: center;
        padding: 1rem;
        width: auto;
    }

    h1.title-font {
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: 0.01em;
        color: #0E2D41;
    }

    .boxes {
        Width: auto;
        Height: auto;
        margin: 0.5rem;
    }

    .ImageSection {
        margin: 1rem;
    }

    .globeimage {
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: -8rem;
    }

    .footer-bg li,
    .footer-bg a {
        font-size: 13px;
    }


}

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {

    .banner-section,
    .box1,
    .box2 {
        background: none;
        background-repeat: no-repeat;
    }
}

@media screen and (min-width: 768px) {
    .login-btn {
        display: none !important;
    }
}


@media screen and (min-width: 1024px) {
    h3.title-font {
        font-size: 41px;
    }

    .login-btn {
        display: block !important;
    }

    .para-text {
        font-size: 19px;
        width: auto;
    }

    h1.title-font {
        font-size: 48px;
        font-weight: 600;
        line-height: 67px;
        letter-spacing: 0.01em;
        color: #0E2D41;
    }

    h2.form-font {
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        line-height: 50px;
    }

    .form-section p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 29.05px;
    }

    .globesection h3 {
        font-size: 38px;
    }

    .globesection p {
        font-size: 15px;
        line-height: 29px;
    }

    .Update-section,
    .form-section {
        padding-left: 2rem;
        padding-right: 1rem;
    }

    .boxs-section {
        padding-left: 2rem;
        padding-right: 0rem;
    }

    .footer-bg li,
    .footer-bg a {
        font-size: 13px;
    }

    .boxes {
        Width: 218px;
        Height: auto;
        padding: 1rem;
    }

    .box-para {
        font-size: 16px;
        line-height: 28px;
    }

    .ImageSection {
        margin: 1rem;
    }

}

@media screen and (min-width: 1280px) {
    .boxes {
        Width: auto;
        Height: auto;
        padding: 2rem;
        margin: 1rem;
    }

    .form-section p {
        font-size: 20px;
    }

    h2.form-font {
        margin-top: 8rem;
    }

    h2.form-font {
        font-size: 40px;
        padding-bottom: 1rem;
    }

    .ImageSection {
        margin: 2rem;
    }

    .globeimage {
        padding-top: 1rem;
    }
}

@media screen and (min-width: 1440px) {
    .para-text {
        font-size: 24px;
        width: auto;
        line-height: 35px;
    }

    .banner-section {
        background: url("../../Assets/Images/bannerbg.webp") 42% 30%;
        background-repeat: no-repeat;
    }

    .box1,
    .box2 {
        background: none;
        background-repeat: no-repeat;
    }

    .box-para {
        font-size: 24px;
        line-height: 36px;
    }

    .banner-text {
        font-size: 22px;
        line-height: 36px;
    }

    .boxs-section,
    .Update-section,
    .form-section,
    .globeimage {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .ImageSection {
        margin: 6rem;
    }
}

@media screen and (min-width: 1921px) {
    body {
        width: 1920px;
        justify-content: center;
        margin: auto;
        overflow-x: hidden !important;
    }
}

@media screen and (min-width: 2560px) {
    body {
        width: 2560px;
        justify-content: center;
        margin: auto;
        overflow-x: hidden !important;
    }
}


.spin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}