nav .nav-btns,
nav .dropdown {
    margin-left: 1rem;
}

/* .forgot-password-section{
    margin-right:0px;
} */

.ContactUs-section .image-side {
    width: 50%;
    margin-right: -4rem;
    height: auto;
    padding: 70px 0 0 45px;
}

.ContactUs-section .image-side img {
    height: 100%;
}

.ContactUs-section .form-side {
    flex: 1 1;
    flex-basis: 50%;
}

.cancelled-btn,
.edited-btn,
.saved-btn {
    width: 114px;
    height: 44px;
    border: 1px solid #CCD7DE;
    border-radius: 6px;
}

.login-section {
    /* margin-right: 0px; */
}

.review-page .ant-table-tbody>tr>td {
    font-size: 12px;
}

.ant-table-wrapper .ant-table-thead>tr>th {
    font-size: 11px;
}

.cancelled-btn:hover,
.edited-btn:hover,
.saved-btn:hover {
    background: var(--sj-blue, #0E2D41);
    opacity: 1;
    color: #fff;
    border: 1px solid #CCD7DE;
    border-radius: 6px;
}

.review-page .page-heading {
    margin-right: 2rem;
}

.ex-employee .tabs-addReviewBtn,
.non-joiners .tabs-addReviewBtn {
    display: none;
}

.hidetab{
    display: none !important;
}
.showTab{
    display: flex !important;
}

.more-filter-btn {
    width: 170px;
}

.reset-button:hover,
.submitt-btn:hover {
    color: #4096ff;
    border-color: #4096ff;
    background: #ffffff;
}

th.ant-table-cell {
    border-start-start-radius: 0px !important;
    border-start-end-radius: 0px !important;
}

.ant-table-wrapper {
    border: 1px solid #ede8e8;
    /* border-radius: 10px; */
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin-right: 2rem;
    display: flex;
    align-items: center;
}

.last-company,
.education,
.reviewheading {
    /* padding-left:0px; */
}

.selection-review {
    align-items: center;
}

.approve-page .search-btn {
    height: 48px;
}

.approve-page .filter-btn {
    height: 48px;
    width: 170px;
}

.issue-div {
    /* border: 1px solid #e7e8f2 */
}

.result-table-head .right-head span:hover {
    color: #fff !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .admin-toggle {
        background-color: #111726;
    }

    .right-btn {
        font-size: 18px;
    }

    .approve-page .result-table-head {
        padding-left: 0px;
        padding-right: 0px;
    }

    .approve-page .form-head,
    .issue-page .form-head {
        display: flex;
        flex-direction: column;
    }

    .approve-page .ant-form.ant-form-vertical .formbody,
    .issue-page .ant-form.ant-form-vertical .formbody {
        display: contents;
    }

    .right-btns {
        margin-top: 1rem;
        justify-content: space-evenly;
    }

    .result-table-head h2 {
        font-size: 16px;
    }

    .approve-page .common-nav {
        flex-direction: row;
    }

    .scroll-div {
        flex-direction: column;
        align-items: baseline;
    }

    .dashboard-home-text {
        font-size: 20px;
        font-weight: 600;
    }

    .selection-review {
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
    }

    .panel-right .ant-layout-content {
        padding: 0px;
    }

    .image-side p {
        padding: 0px;
    }

    .image-side img {
        margin-top: 2rem;
    }

    .ContactUs-section .image-side {
        width: 100%;
        height: auto;
        padding: 70px 0 0 45px;
    }

    .review-id {
        padding-left: 1rem;
        flex-direction: column;
    }

    .search-page nav.admin-top-nav {
        padding: 10px;
        padding-left: 4rem;
    }

    .ex-employee .panel-right {
        padding: 0 0px;
    }

    .contact-flex {
        display: flex;
        flex-direction: column;
    }

    .users-page .admin-toggle {
        margin-top: 10px
    }

    .user-list-table {
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: thin;
        border: 1px solid #E7E8F2;
    }

    .user-list-table h2,
    .result-table-head {
        border: 0px !important;
    }

    .user-list-table .ant-table-wrapper {
        border: 0px;
        border-radius: 10px;
    }

    .toggle-nav {
        z-index: 9;
        position: absolute;
        left: 4.1rem;
        top: 4rem;
        padding: 4rem;
        height: 100%;
        background-color: #fff;
    }

    nav.admin-top-nav {
        padding: 10px 10px;
    }

    .profile-page .admin-top-nav {
        margin-left: 4rem;
        margin-top: -3.5rem;
    }

    .expand-box .search-table-section .form-fields {
        width: 100%;
    }

    .search-table-section .form-fields input {
        width: 100%;
    }

    .expand-box .search-table-section {
        flex-wrap: wrap;
        gap: 5px;
    }

    .users-page .user-list-table .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 11px;
    }

    .point-image-side {
        width: 100%;
        padding-right: 0rem;
    }

    .profile-page .caption-top {
        padding-right: 0px;
        margin-right: -1rem;
        margin-left: -0.5rem;

    }

    .dropdown-logout {
        right: 3rem !important;
    }

    .popup-form-dashboard {
        width: 100%;
    }

    nav .nav-btns.points-count,
    nav .nav-btns {
        margin-bottom: 1rem;
    }

    .admin-name-txt {
        font-size: 15px;
    }

    .dropdown button.flex {
        margin-right: 0px;
    }

    .toggle-btns.col {
        display: flex;
        margin-left: -1rem;
    }

    .home-section .flex {
        display: flex;
        flex-direction: column;
    }

    .home-flex {
        padding-right: 0.5rem;
        padding-left: 1rem;
    }

    .image-side-table .point-history {
        height: 100%;
    }

    .home-section {
        padding-left: 1rem !important;
        padding-right: 1rem;
    }

    .fill-control-indicator {
        margin-left: 1rem;
    }

    .fill-control-description {
        padding-left: 3rem;
    }


    .tabs-addReviewBtn button {
        height: 42px;
        font-size: 10px;
    }

    .ex-employee-form .field-container {
        width: 100%;
    }

    .first-section {
        display: flex;
    }

    .ex-employee .tabs-addReviewBtn,
    .ex-employee .reviewheading,
    .first-section {
        padding: 1rem;
    }

    .ex-employee h3 {
        padding: 0px;
    }

    h3.block.admin-name-txt {
        font-size: 10px;
    }

    .last-company,
    .education {
        padding-top: 0px;
        padding-left: 1rem;
    }

    .review-bottom-btns {
        padding-right: 1rem;
    }

    .max-w-sm.overflow-hidden.billing-card {
        max-width: 18rem;
    }

    .photo-update h3 {
        padding-bottom: 1rem;
    }

    .users-page .ant-spin-container {
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
        border: 1px solid #E7E8F2;
    }

    .users-page .ant-table-wrapper .ant-table-thead>tr>th {
        font-size: 9px;
    }

    .users-page .panel-right {
        padding: 0 0px;
    }

    .delete-user-btn {
        font-size: 11px;
    }

    .users-page nav.admin-top-nav {
        padding: 0px;
    }

    .review-page .ant-table-wrapper {
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
        border: 1px solid #E7E8F2;
    }
}

@media (width: 768px) {
    nav .nav-btns {
        min-width: 106px;
        height: 37px;
    }

    /* .more-filter-btn {
            width: 170px;
        } */
    .ContactUs-section .image-side {
        margin-right: 0;
    }

    .image-side p {
        font-size: 24px;
    }

    .review-id {
        padding-left: 9rem;
    }

    .user-img {
        height: 28px;
        width: 28px;
    }

    .signup-screen-section .image-side {
        padding: 173px 0 0 50px;
    }

    nav .nav-btns.points-count {
        font-size: 10px;
    }

    nav .nav-btns.points-count img {
        height: 10px;
    }

    .admin-name-txt {
        font-size: 9px;
    }

    .dropdown button.flex {
        gap: 11px;
        width: 150px;
    }

    /* #logo-sidebar{
            width:11rem;
        }  */
    .admin-txt {
        font-size: 9px;
    }

    nav.admin-top-nav {
        gap: 0px;
        /* margin-left: 7rem; */
    }

    nav .nav-btns .add-user {
        font-size: 10px;
        padding: 0px 10px 0px 0px;
    }

    .page-heading {
        font-size: 10px;
    }

    .super-admin-logout {
        height: 37px;
    }

    .Adminusericon {
        width: 36px;
        height: 37px;
    }

    .site-layout-background .tabs-addReviewBtn.flex.justify-between.px-10.py-6.row {
        background-color: #fff;
        padding-left: 9rem
    }

    /* .form-side {
            flex: 1 1;
            flex-basis: 50%;
        } */
    /* .user-list-table {
            margin-left: 7rem;
        } */

    .user-list-table .ant-table-wrapper .ant-table-tbody>tr>td,
    .ant-table-wrapper .ant-table-thead>tr>th {
        font-size: 9px;
    }

    .dashboard-page caption {
        font-size: 12px;
    }

    .dropdown button.flex {
        gap: 11px;
        width: 170px;
    }

    /* 
        .heading-home {
            padding-left: 8rem;
        } */

    .popup-form-dashboard {
        width: 50%;
    }

    .tabs-addReviewBtn {
        padding: 45px 30px;
        /* margin-left: 7rem; */
    }

    .ex-employee .dropdown button {
        width: 160px;
        padding-right: 0;
    }

    .ex-employee h3.block.admin-name-txt {
        font-size: 10px;
    }

    .ex-employee .reviewheading,
    .ex-employee .education h3 {
        margin-left: 7rem;
    }

    .ex-employee .add-review-list,
    .ex-employee .last-section-box,
    .ex-employee .education-section {
        width: 80%;
        margin-left: 7rem;
    }

    .ex-employee .last-company h3 {
        margin-left: 7.3rem;
    }

    .ex-employee .page-heading {
        font-size: 16px;
    }

    .users-page .page-heading {
        font-size: 16px;
        font-weight: 600;
    }

    .first-section {
        padding-left: 0px;
    }

    .review-id {
        padding-left: 7rem;
    }

    .ex-employee .tabs-addReviewBtn {
        padding: 45px 30px;
        margin-left: 5rem;
    }


    .review-page .ant-table-wrapper {
        overflow-y: scroll;
        scrollbar-width: thin;
        border: 1px solid #E7E8F2;
        /* margin-left: 7rem; */
    }

    .review-page .tabs-addReviewBtn {
        padding: 45px 0px;
        /* margin-left: 7rem; */
    }

    .review-page .page-heading {
        font-size: 16px;
    }

    .contact-div {
        padding: 0rem;
        margin: 0px;
    }

    .ContactUs-section .form-side {
        width: 50%;
    }

    .forgot-password-section h1,
    .login-section h1 {
        font-size: 25px;
    }

}

@media screen and (max-width: 1024px) {
    .gap {
        gap: 1rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"] {
        font-size: 10px;
    }

    .dashboard-blocks h2 {
        font-size: 12px;
    }

    .dashboard-blocks .ant-table-wrapper .ant-table-tbody>tr>th,
    .dashboard-blocks .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 12px;
    }

    .forgot-password-section h1,
    .login-section h1 {
        font-size: 22px;
    }

    .auth-bottom-text {
        font-size: 14px;
    }

    .ContactUs-section .image-side {
        margin-right: 0;
    }

    .review-page .ant-table-wrapper {
        width: auto;
        overflow-x: scroll;
        scrollbar-width: thin;
        border: 1px solid #E7E8F2;
    }

    /* .review-page .ant-table-tbody >tr >td {
        font-size: 10px;
    } */
    .review-id,
    .first-section {
        padding: 0px;
    }

    .ex-employee .page-heading {
        font-size: 16px;
        /* margin-left: 1rem; */
    }

    .review-bottom-btns {
        margin-right: 0.5rem;
    }

    .ex-employee .last-company h3,
    .ex-employee .education h3 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .page-heading {
        /* margin-left: 1rem; */
        font-size: 18px;
    }

    .photo-update h3 {
        padding-top: 1rem;
    }

    .users-page .user-list-table .ant-table-wrapper .ant-table-tbody>tr>td {
        font-size: 11px;
    }

    .users-page .user-list-table h2 {
        font-size: 14px;
    }
}

@media screen and (min-width: 1440px) {

    /* .review-page nav.admin-top-nav {
        margin-left: 1.2rem;
    }  */
    .ex-employee .last-company h3 {
        /* padding-top: 0px;
        padding-bottom: 0px; */
    }
}


@media (width: 2560px) {

    .login-section,
    .forgot-password-screen,
    .signup-screen-section {
        margin-right: auto;
    }
}