@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Inter";
  font-style: normal;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  height: 48px;
  border: 1px solid #ccd7de;
  width: 100%;
  border-radius: 6px;
  padding: 0 12px;
  color: #647b8f;
  font-size: 16px;

}

.password-eye svg {
  color: #999b9c;
}

.profile-container {
  padding-left: 0;
}

/* check box */

.blue-checkbox .fill-control-input:checked~.fill-control-indicator {
  background-color: #0976da;
  border-color: #0976da;
}

.fill-control-input {
  display: none;
}

.fill-control-indicator {
  border-radius: 3px;
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 2px solid #aaa;
  transition: 0.3s;
  position: absolute;
  margin-left: -20px;
  background: transperent;
  background-size: 0%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.arrow-svg-icon svg {
  margin-left: 0.6rem;
  margin-top: 0.6rem;
}

.add-user-popup {
  z-index: 999;
}

.fill-control-description {
  color: #547187;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
  padding-left: 6px;
}

.logo {
  padding: 24px 0;
}

.dashboard-table-border {
  border-radius: 6px;
  border: 1px solid var(--sj-strokegray, #e7e8f2);
  background: rgba(255, 255, 255, 0.82);
}

.custom-input {
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 16px;
}

.top-border {
  border-radius: 6px 6px 0 0;
}

.pie-chart-home {
  display: flex;
  margin: auto;
}

.dashboard-profile {
  padding-left: 17rem;
}

.forget-password-padding {
  padding-left: 7rem;
  padding-right: 7rem;
}

.login-page-wrap {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  align-items: stretch;
}

.form-side {
  flex: 1;
  flex-basis: 70%;
  padding-left: 5%;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 130px 60px 50px 60px;
}

.login-form form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.image-side {
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  padding: 70px 0 0 80px;
  background-size: cover !important;
  flex-direction: column;
  background: url("./Assets/Images/login-grey-bg.png") no-repeat top center;
}

.signup-screen-section .image-side {
  padding: 120px 0 0 80px;
}

.signup-screen-section .login-form {
  padding-top: 40px;
}

.MuiFormControl-root {
  width: 100%;
}

/* .forgot-password-screen,
.signup-screen-section {
  margin-right: 0px;
} */

.login-form .MuiOutlinedInput-input {
  padding: 0 14px;
  height: 48px;
  width: 100%;
}

.login-form textarea {
  padding: 5px 14px;
  width: 100%;
  height: 122px;
  border-radius: 6px;
  border: 1px solid #CCD7DE;
}

.login-form .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 15px) scale(1);
  pointer-events: none;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -9px) scale(0.75);
  background: #fff;
  padding: 2px 5px;
}

.login-form .MuiOutlinedInput-notchedOutline {
  border-radius: 6px;
  border-width: 1px;
  border-color: #ccd7de;
}

.MuiInputAdornment-positionEnd {
  margin-left: 8px;
  position: absolute;
  right: 0px;
}

.forgot-pwd {
  display: flex;
  justify-content: flex-end;
}

.forgot-pwd a {
  font-size: 15px;
  color: #647b8f;
  font-weight: 500;
}

.auth-btn {
  width: 100%;
  background-color: #0e2d41;
  font-size: 14px;
  border-radius: 6px;
  height: 45px;
  color: #fff;
  /* margin-top: 25px; */
  margin-top: 20px;
}

.image-side p {
  width: 100%;
  font-size: 24px;
  text-align: left;
  color: #3f3c47;
  font-weight: 500;
  padding: 0 80px 30px 0;
}

.image-side p.name-text {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.image-side p.name-text span {
  font-size: 14px;
  color: #898989;
}

.second-judge-banner {
  height: auto;
  margin: auto 0 0 auto;
}

.padding-top-1 {
  padding: 6rem;
}

#region,
label,
.list-background svg {
  color: #647b8f !important;
}

.image-side-table .point-history {
  height: 372px;
}

.textarea-contactus {
  font-size: 1rem;
  color: #647b8f !important;
}

/* textarea::placeholder {
  color: #647B8F !important;
} */

.newpassword .newpassword-btn {
  margin-top: 26px !important;
}

.nav-logo {
  width: 155px;
  height: 40px;
}

.responsehidden {
  visibility: hidden;
}

#detail-email {
  margin-top: -4.6rem;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  /* min-height: 56px !important; */
  min-height: 48px !important;
  border-radius: 6px;
}

.home-section {
  background: #fff;
}

.heading-home {
  background: #fff;
  padding: 20px 0 20px;
  display: block;
}

.dashboard-row {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.dashboard-blocks {
  flex: 1;
  margin-bottom: 50px;
}

.blocks-body {
  border: 1px solid #E7E8F2;
  border-radius: 0 0 6px 6px;
  min-height: 370px;
}


.dashboard-blocks .dashboard-header {
  display: flex;
  height: 60px;
  justify-content: space-between;
  border: 1px solid #E7E8F2;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  padding: 0 16px;
  color: #0E2D41;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.16px;
  align-items: center;
}

.dashboard-blocks h2 {

  color: #0E2D41;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.16px;
}
.toggle-buttons .ant-tabs {
  display: block;
  height: 40px;
}
.dashboard-blocks .ant-tabs-nav {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.dashboard-blocks .ant-tabs-nav-list {
  background: #F1F5F8;
  border-radius: 10px;
}

.dashboard-blocks .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.dashboard-blocks .ant-tabs .ant-tabs-tab+.ant-tabs-tab:first-child {
  border-radius: 10px 0 0 10px;
}

.dashboard-blocks .ant-tabs .ant-tabs-tab+.ant-tabs-tab:last-child {
  border-radius: 0 10px 10px 0;
}

.dashboard-blocks .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: transparent;
}

.dashboard-blocks .ant-tabs-top>.ant-tabs-nav::before,
.dashboard-blocks .ant-tabs-top>div>.ant-tabs-nav::before {
  border: 0;
}

.dashboard-blocks .ant-tabs .ant-tabs-tab-btn {
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.subscription-chart {
  width: 100%;
}

a.view-all {
  display: flex;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #6A8698;
  justify-content: flex-end;
}

td.ant-table-cell.table-date {
  width: 25%;
}

.dashboard-blocks .ant-table-wrapper .ant-table-tbody>tr>th,
.dashboard-blocks .ant-table-wrapper .ant-table-tbody>tr>td {
  color: #0E2D41;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
}

.dashboard-blocks .ant-table-wrapper {
  border-radius: 0 0 6px 6px;
  border: 1px solid #E7E8F2;
}

.dashboard-blocks .ant-table-wrapper .ant-table,
.dashboard-blocks .ant-table-wrapper .ant-table .ant-table-container,
.dashboard-blocks .ant-table-wrapper .ant-table .ant-table-container table>thead>tr:first-child>*:first-child,
.dashboard-blocks .ant-table-wrapper .ant-table .ant-table-container table>thead>tr:first-child>*:last-child {
  border-radius: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border: 0;
}

.recharts-wrapper {
  margin: auto;
}

span.recharts-legend-item-text {
  color: #547187 !important;
}

.donut-pie:focus,
.donut-pie:active {
  outline: none;
  /* Remove outline on focus or active state */
  border: none;
  /* Remove border on focus or active state */
}

.companyreport-id {
  color: #2c2c34;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-top: -5rem;
}

.review-text {
  color: var(--sj-blue, #0e2d41);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.12px;
}

.companyreportsave-page .ex-employee-textarea {
  height: 55%;
}

.companyreportdelete-page .ex-employee-textarea {
  height: 62%;
}

.issuecreated-page .ex-employee-textarea {
  height: 87%;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.login .account-para {
  display: flex;
  justify-content: center;
}

.login .btn-div {
  margin-top: 15px;
}

.login .submit-btn {
  margin-top: 32px !important;
}

.newpassword .MuiInputAdornment-positionEnd {
  margin-left: -2rem;
}

.css-1dimb5e-singleValue,
#react-select-3-placeholder {
  font-size: 1rem;
  color: #647b8f !important;
  font-weight: normal;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control {
  border-color: #647b8f !important;
}

#react-select-5-listbox,
.css-1nmdiq5-menu {
  z-index: 999;
}

.gradient-btn {
  height: 45px;
}

.popup-form {
  z-index: 99;
}

.hide-join {
  display: none;
}

.non-ex-employee {
  padding-left: 18rem;
}

.profile-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
  margin-left: 10px;
}

.login-section .resend-otp {
  color: #647b8f;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.add-review-btn {
  border-radius: 6px;
  height: 42px;
  border: 1px solid #e7e8f2;
  background: rgba(255, 255, 255, 0.82);
  box-shadow: 0px 2px 8px 0px rgba(13, 16, 33, 0.08);
}

#react-select-3-listbox,
.css-1nmdiq5-menu,
#react-select-2-listbox {
  z-index: 999999;
  margin-top: -1px;
}

.list-background {
  height: 56px;
}

.point-history h5 {
  color: #0e2d4199;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.115px;
  /* 136.538% */
  letter-spacing: 1.092px;
  text-transform: uppercase;
}

.point-img {
  margin-top: -50px;
  width: 330px;
  display: flex;
  float: right;
}

.point-history h4 {
  color: var(--sj-blue, #0e2d41);
  font-size: 32px;
  font-weight: 600;
  line-height: 43.692px;
  /* 136.538% */
  letter-spacing: -0.546px;
}

.required-text {
  /* margin-top: -15px; */
  /* margin-bottom: 15px; */
  margin-left: 2px;
}

.padding-top-1 {
  padding-top: 7rem;
}

/* Reviews.css */
nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

nav .nav-btns {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #d8e1e8;
  height: 50px;
  min-width: 135px;
  justify-content: center;
}

nav .nav-btns.points-count {
  background: #0e2d41;
  color: #fff;
  font-size: 13px;
}

nav .nav-btns .add-user {
  font-size: 14px;
  color: #0e2d41;
  padding: 0 15px 0 0;
  font-weight: 500;
}

#navbar-default {
  display: flex;
}

.panel-right {
  padding: 0 0;
}

nav.admin-top-nav {
  background: #fff;
  padding: 20px 30px;
  box-shadow: -1px 0px 6px 0px #0A19331F;
  display: flex;
  justify-content: space-between;

}

.panel-right .ant-layout-content {
  padding: 10px 30px;
}

.page-heading {
  color: #0e2d41;
  font-size: 20px;
  font-weight: 500;
}

.sidebar-col {
  padding: 0;
}

.main-col {
  padding: 0;
}

.caption-top {
  caption-side: top;
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
}

.caption-top h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: -0.02em;

}

.change-pwd-btns {
  display: flex;
  gap: 20px;
}

.change-pwd-btns button {
  height: 42px;
  border-radius: 6px;
  border: 1px solid #CCD7DE;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.2px;

}

.btn-primary {
  padding: 10px 15px;
  border-radius: 6px;

}

.dark-btn {
  background: #0E2D41;
  color: #fff !important;
}

.dark-btn {
  background: #0E2D41;
  color: #fff !important;
}

section.flex-profile {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 30px;
}

section.flex-profile .field-container {}

.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.tab-button {
  margin: 0 0.5rem;
}

.tab-button.active {
  background-color: #343a40;
  /* Dark theme for active tab */
  color: white;
}

.add-review-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.add-review-button {
  border: 1px solid #343a40;
}


.site-layout-background {
  background-color: #fff !important;
}

.add-review-form {
  position: relative;
}

.tabs-addReviewBtn {
  padding: 25px 0;
}

.dashboard-page .tabs-addReviewBtn {
  padding: 25px 0;
}

.employeeListTable {
  padding: 0;
}

.tabs-addReviewBtn button {
  height: 42px;
  font-size: 12px;
}

.user-list-table h2,
.result-table-head {
  border: 1px solid #e7e8f2;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #0e2d41;
  padding: 18px 20px 24px;
}

.result-table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-table .ant-table-wrapper .ant-table {
  border-radius: 0 0 8px 8px;
}

.user-list-table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child,
.user-list-table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child,
.user-list-table .ant-table-wrapper {
  border-start-end-radius: 0;
  border-start-start-radius: 0;
}

.user-list-table .ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 16px 16px;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
}

.result-table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.result-table-head h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.12px;
  padding: 0;
  border: 0;
}

.result-table-head .right-head {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: #647B8F;

}

.result-table-head .right-head span {
  font-weight: 600;
  color: #187edc;
}
.ant-btn-primary.btn.btn-primary.dark-btn span{
  color: #fff ;
}

.inactive-user,
.inactive-row {
  color: #8d99a1;
}

.addUserBtn {
  justify-content: flex-end;
}

.ant-pagination .ant-pagination-item a {
  color: #0d2436;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #0d2436;
  border-color: #0d2436;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #f1f1f1;
  background: #ffffff;
  height: 33px;
}

.field-container .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  border: 1px solid #ccd7de;
  width: 100%;
  border-radius: 6px;
  padding: 0 12px;
  color: #647b8f;
  font-size: 16px;
}

.review-page.ex-employee svg {
  color: #000;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff;
  border: 0;
}

.addUserBtn button {
  height: 42px;
  padding: 0 20px;
  gap: 10px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px #0d102114;
  border: 1px solid #e7e8f2;
  font-size: 14px;
  font-weight: 600;
  color: #051623;
  letter-spacing: 0.12px;
}

.delete-user-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #fb1a35 !important;
  padding: 0;
}

.block-user-btn {
  color: #0E2D41 !important;
}

.ant-popconfirm-buttons .ant-btn-primary {
  background: #111827;
}

.tabs-addReviewBtn .toggle-btns button:first-child {
  border-radius: 10px 0 0 10px;
}

.tabs-addReviewBtn .toggle-btns button:last-child {
  border-radius: 0 10px 10px 0;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background: #f1f5f8;
  color: #647b8f;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 16px;
}

.ant-table-wrapper .ant-table {
  border-radius: 8px;
}

.field-container {
  position: relative;
  /* margin-bottom: 1 rem; */
}

.field-container label.placeholders {
  position: absolute;
  top: 12px;
  left: 9px;
  transition: 0.2s ease all;
  color: #aaa;
  pointer-events: none;
  /* background: white; */
  padding: 0 4px;
  font-size: 16px;
}

.upload-container label.upload-button {
  position: relative;
  top: 0;
  left: 0;
}

.field-container input::placeholder,
.field-container textarea::placeholder {
  color: transparent;
  /* Hide the placeholder text */
}
.field-container input::placeholder,
.field-container textarea::placeholder {
  color: transparent;
  /* Hide the placeholder text */
}
.field-container .upload-container input::placeholder {
  color: #647b8f;
}

.field-container input:focus+label.placeholders,
.field-container textarea:focus+label.placeholders,
.field-container input:not(:placeholder-shown)+label.placeholders, 
.field-container textarea:not(:placeholder-shown)+label.placeholders  {
  top: -0.5rem;
  left: 5px;
  font-size: 12px;
  color: #000;
  background: #fff;
}

/* .field-container input:focus+label.upload-button,
.field-container input:not(:placeholder-shown)+label.upload-button {
  top: 0;
  left: 0;
  font-size: 14px;
  color: #647B8F;
} */

.field-container input {
  padding: 1rem 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.field-container p {
  color: red;
  margin-top: 0.5rem;
}

.ex-employee input,
.ex-employee .css-b62m3t-container,
.ex-employee .css-13cymwt-control {
  height: 48px;
  color: #647b8f;
  border: 1px solid #ccd7de;
  width: 100%;
  border-radius: 6px;
  padding: 0 12px;
  color: #647b8f;
  font-size: 16px;
}

.non-joiners input,
.non-joiners .css-b62m3t-container,
.non-joiners .css-13cymwt-control {
  height: 48px;
  color: #647b8f;
  border: 1px solid #ccd7de;
  width: 100%;
  border-radius: 6px;
  padding: 0 12px;
  color: #647b8f;
  font-size: 16px;
}

/* .ex-employee input::placeholder {
  color: #647B8F;
} */

.ex-employee textarea {
  color: #647b8f;
  border: 1px solid #ccd7de;
  width: 100%;
  height: auto;
  border-radius: 6px;
  padding: 30px 12px;
  color: #647b8f;
  font-size: 16px;
}

.non-joiners textarea {
  color: #647b8f;
  border: 1px solid #ccd7de;
  width: 100%;
  border-radius: 6px;
  padding: 30px 12px;
  color: #647b8f;
  font-size: 16px;
}

.photo-update input {
  display: none;
}

.upload-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccd7de;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 48px;
}

.ex-employee .text-input {
  flex: 1;
  border: none;
  outline: none;
}

input::placeholder {
  color: #9ca3af;
  /* Default gray-400 color */
  font-size: 14px;
}

.non-joiners .text-input {
  flex: 1;
  border: none;
  outline: none;
}

.non-joiners .upload-button {
  background-color: #f1f5f8;
  padding: 10px 30px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #d3e1ec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #647b8f;
  font-size: 14px;
  height: 46px;
}

.ex-employee .upload-button {
  background-color: #f1f5f8;
  padding: 10px 30px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #d3e1ec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #647b8f;
  font-size: 14px;
  height: 46px;
}

.ex-employee .file-input {
  display: none;
}

.ex-employee svg {
  color: #fff;
}

.non-joiners .file-input {
  display: none;
}

.non-joiners svg {
  color: #647b8f;
}

.ex-employee .css-b62m3t-container {
  margin-bottom: 0;
  height: 48px;
}

.ex-employee .css-13cymwt-control {
  min-height: 48px !important;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.ex-employee .css-1fdsijx-ValueContainer {
  padding: 0;
}

.ex-employee h3 {
  font-size: 16px;
  color: #0e2d41;
  padding: 42px 0 20px;
  font-weight: 600;
}

.non-joiners .css-b62m3t-container {
  margin-bottom: 0;
  height: 48px;
}

.non-joiners .css-13cymwt-control {
  min-height: 48px !important;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.non-joiners .css-1fdsijx-ValueContainer {
  padding: 0;
}

.non-joiners h3 {
  font-size: 16px;
  color: #0e2d41;
  padding: 42px 0 20px;
  font-weight: 600;
}

.dropdown h3 {
  padding: 0;
}

.approve-page .css-13cymwt-control {
  min-height: 42px !important;
}

.close-btn {
  display: none;
}

.ex-employee .css-t3ipsp-control,
.approve-page .css-t3ipsp-control {
  height: 42px;
  min-height: 42px !important;
  border: 0;
}

.non-joiners .css-t3ipsp-control,
.approve-page .css-t3ipsp-control {
  height: 42px;
  min-height: 42px !important;
  border: 0;
}

.ant-select-single {
  font-size: 16px;
  height: 48px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  font-size: 16px;
  color: #9ca3af;
}

/* .approve-page .css-qbdosj-Input{
  height: 32px;
} */
.non-joiners .css-qbdosj-Input,
.non-joiners .css-19bb58m,
.approve-page .css-19bb58m,
.approve-page .css-13cymwt-control {
  height: 42px;
}

.non-joiners .css-qbdosj-Input {
  border: 0;
}

.ex-employee .css-qbdosj-Input,
.ex-employee .css-19bb58m,
.approve-page .css-19bb58m,
.approve-page .css-13cymwt-control {
  height: 42px;
}

.ex-employee .css-qbdosj-Input {
  border: 0;
}

.review-bottom-btns {
  gap: 20px;
}

.review-bottom-btns button {
  background: #f1f5f8;
  border: 1px solid #d3e1ec;
  color: #647b8f;
  width: 100px;
  height: 42px;
  line-height: 40px;
  border-radius: 6px;
}

button.dark-mode {
  background: #0e2d41;
  color: #fff;
}

.dropdown-logout {
  border-radius: 6px;
  border: 1px solid #ccd7de;
  list-style: none;
  padding: 3px;
  width: 200px;
  margin-top: 2px;
  right: 15px;
  background: #fff;
}

.logout-link {
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  background: #f8fafb;
  color: #647b8f;
  padding: 10px 20px;
}

.blue-indicator {
  background: #0976da;
  border: none;
}

.ex-employee-form {}

.ex-employee-form .field-container {
  width: 47%;
}

.ex-employee-form.non-employee-form {
  /* height: 1350px;
  margin-top: 450px; */
}

.non-joiners-form {}

.non-joiners-form .field-container {
  width: 47%;
}

.non-joiners-form.non-employee-form {
  /* height: 1350px;
  margin-top: 450px; */
}

.dash-nav-link {
  margin-top: 20px !important;
}

.logout-link:hover {
  color: #000;
}

.edit-btn {
  padding: 0.8rem 2.9rem;
  border-radius: 6px;
  background: var(--sj-blue, #0e2d41);
  opacity: 1;
}

.changepassword-btn {
  padding: 0.8rem 1rem;
  border-radius: 6px;
  background: var(--sj-blue, #0e2d41);
  opacity: 1;
}

.edit-btn2 {
  padding: 0.8rem 12px;
  border-radius: 6px;
  background: var(--sj-blue, #0e2d41);
  opacity: 1;
}

.side-navbar-a,
.drop-button {
  display: flex;
  padding-left: 25px;
  gap: 15px;
  font-size: 15px;
}

.drop-button {
  width: 100%;
}

/* #dropdown-container {
  background: transparent;
  padding-left: 8px;
  border-left: 2px solid #2c4558;
  padding-left: 10px;
  margin-left: 35px;
} */

.ant-select .ant-select-selector,
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ccd7de;
  background: #ffffff;
  height: 48px;
}

.form-fields {
  width: 30%;
}

.view-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-decoration: underline;
  color: #0976da;
}

.search-table-section .form-fields input,
.search-select {
  border: 1px solid #e7e8f2;
  height: 40px;
  border-radius: 6px;
  padding: 0 10px;
}

.search-table-section .form-fields input {
  width: 90%;
}

.expand-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 25px;
}

button.more-filter-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

button.more-filter-btn svg {
  fill: #051623;
}

button.more-filter-btn.expanded {
  background: #051623;
  color: #fff;
}

button.more-filter-btn.expanded svg {
  fill: #fff;
}

/* .expand-box .search-select {
  width: 27%;
} */

.expand-box .search-table-section {
  flex-wrap: wrap;
  gap: 25px;
}

.expand-box .search-table-section .form-fields {
  width: 45%;
}

.expand-box .search-table-section .form-fields input,
.expand-box .search-table-section .form-fields .search-select {
  width: 100%;
}

.expand-box .search-table-section .form-fields .search-select {
  padding: 0;
}

.expand-box .search-table-section .form-fields .search-select .ant-select-selector {
  height: 38px;
  border: 0;
}

.expand-box .search-table-section .form-fields .ant-picker {
  width: 100%;
  padding: 0;
  border: 1px solid #e7e8f2;
}

.expand-box .search-table-section .form-fields .ant-picker .ant-picker-input input {
  width: 100%;
  border: 0;
  font-size: 16px;
  color: #647b8f;
  font-weight: 500;
}

.expand-box .search-table-section .form-fields .ant-picker-suffix,
.expand-box .search-table-section .form-fields .ant-picker-clear {
  padding: 0 10px;
}

.expand-box .search-table-section .form-fields .anticon svg {
  fill: #000;
}

.search-right .search-btn-cont {
  display: flex;
  justify-content: end;
  margin-bottom: 25px;
  gap: 20px;
}

.search-right .search-btn-cont button {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 44px;
  padding: 12px 20px 12px 20px;
  gap: 6px;
  border-radius: 6px;
  background: #051623;
  color: #fff;
  font-weight: 500;
}

.search-right .search-btn-cont button.clear-btn {
  background: #fff;
  color: #051623;
  border: 1px solid #d9d9d9;
}

span.ant-select-arrow svg {
  color: #000;
}

.employee-details {
  width: 100%;
}

.employee-details .form-head,
.form-head {
  display: flex;
  justify-content: space-between;
}

.employee-details .form-head h2,
.form-head h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.employee-details .form-head .right-btns,
.form-head .right-btns {
  display: flex;
  gap: 20px;
}

.right-btns {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.employee-details .form-head button,
.right-btns button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.12px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  height: 42px;
  border-radius: 6px;
  /* background: transparent; */
  padding: 0 30px;
  color: #051623;
  border: #d9d9d9 1px solid;
}

.employee-details .form-container h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.19px;
  padding: 20px 0 10px;
  border-bottom: 1px solid #e7e8f2;
}

.employee-details .form-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0;
  gap: 30px;
}


.employee-details .form-data {
  display: flex;
  flex-direction: column;
  flex-basis: 47%;
}

.employee-details .form-data.form-row-data {
  flex-direction: row;
}

.employee-details .form-data.form-row-data .form-group-data {
  flex-basis: 40%;
}

.employee-details .form-data label {
  font-weight: bold;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #0e2d41 !important;
  margin-bottom: 5px;
  font-weight: 600;
}

.employee-details .form-data p {
  width: 100%;
  /* height: 42px;
  border-radius: 6px;
  border: 1px solid #e7e8f2; */
  color: #637B8E;
  line-height: 30px;
  margin: 0;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 500;
}

#dropdown-container {
  background: transparent;
  padding-left: 10px;
  margin-left: 35px;
  margin-top: 10px !important;
  position: relative;
}

#dropdown-container:before {
  content: "";
  position: absolute;
  top: -0;
  left: 0;
  height: 78%;
  border-left: 2px solid #2c4558;
}

#dropdown-container a {
  display: none;
  text-decoration: none;
  color: #d3e1ec;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 8px 12px 8px 12px;
  height: 35px;
}

#dropdown-container a::before {
  background: #2c4558;
  content: "";
  width: 20px;
  height: 2px;
  margin: 8px 5px 0 -22px;
  display: inline-block;

}

.drop-button {
  color: #d3e1ec;
  background: transparent;
}


.show-dropdown a {
  display: block !important;
}

#dropdown-container a:hover {
  border-radius: 40px;
  background: #2c4558;
}

.main {
  margin-left: 250px;
  float: left;
}

.MuiInputAdornment-positionEnd svg {
  color: #999b9c;
}

.upload-btn {
  border-radius: 6px;
  border: 1px solid #d3e1ec;
  background: var(--sj-cancel, #f1f5f8);
  width: 70px;
  height: 100%;
  position: absolute;
  right: 0;
  font-size: 13px;
}

.upload-img-btn {
  border-radius: 6px;
  border: 1px solid #d3e1ec;
  background: var(--sj-cancel, #f1f5f8);
  height: 30%;
  padding: 5px;
}

.admin-caption1 {
  border-bottom: 1px solid rgb(209 213 219 / var(--tw-border-opacity));
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.admin-caption2 {
  font-size: 1rem;
}

.profile-txt {
  font-size: 24px;
}

.add-user-popup {
  color: #647b8f;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  position: fixed;
  top: 0;
  right: 0;
}

.add-user-popup form {
  padding: 30px 0;
}

.add-user-popup form .add-user-header {
  padding: 0 30px 20px;
}

.add-user-popup form .add-user-header h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  letter-spacing: 0.002em;
  color: #0e2d41;
}

.add-user-popup form .add-user-header p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #647b8f;
}

.add-user-popup form .add-user-body {
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.add-user-popup form .add-user-body-superadmin {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.add-user-popup form .add-user-body input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  padding: 0 15px;
}

.add-user-popup form .add-user-body textarea {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  padding: 10px 15px;
  border: 1px solid #e7e8f2;
}

.add-user-popup form .add-user-footer {
  display: flex;
  padding: 0 30px 30px;
  justify-content: space-evenly;
}

.add-user-popup form .add-user-footer button {
  background: transparent;
  border: 1px solid #e7e8f2;
  padding: 0 30px;
  height: 42px;
  border-radius: 6px;
  color: #051623;
  font-size: 14px;
  box-shadow: 0px 2px 8px 0px #0d102114;
}

.add-user-popup form .add-user-footer button.submit-btn {
  background: #0d2436;
  color: #fff;
}

p.required-text {
  color: #ff2727;
  margin: 5px 0 0 0;
}

.user-img {
  height: 46px;
  width: 46px;
}

.multiField-container {
  width: 49%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.admin-name-txt {
  color: #0a1933;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0 !important;
}

.admin-txt {
  color: #6d7b92;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-top: 4px;
}

.Adminusericon {
  width: 44px;
  height: 44px;
}

.super-admin-logout {
  height: 48px;
}

.ex-employee #react-select-5-placeholder,
.ex-employee .css-1jqq78o-placeholder {
  color: #647b8f !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.non-joiners #react-select-5-placeholder,
.non-joiners .css-1jqq78o-placeholder {
  color: #647b8f !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

/* .billing-page
  .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
  margin-left: 17rem;
} */

.reviewheading {
  color: #2c2c34;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.css-13cymwt-control svg {
  color: #647086;
}

.search-table {
  background: var(--sj-box-gray, #f1f5f8);
}

.search-result {
  color: #0d1021;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.12px;
}

.search-table tr th {
  color: var(--sj-boxtext, #647b8f);
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.dropdown button svg {
  color: #647086;
  margin-left: 0 !important;
}

.dropdown button.flex {
  gap: 1rem;
}

.billing-section {
  display: flex;
  gap: 20px;
  border: 1px solid #E7E8F2;
  border-radius: 6px;
  padding: 20px;
}

.subscription-header {
  background: #f1f5f8;
}

.subscription-header tr th {
  color: #647b8f;
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.subscription-body tr td,
.subscription-body div {
  color: var(--sj-blue, #0e2d41);
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
}

.billing-page-heading {
  color: var(--sj-blue, #0e2d41);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.121px;
  text-transform: uppercase;
}

.white-logo {
  width: 132px;
  height: 32px;
  display: flex;
  margin: auto;
  margin: auto auto auto 13px;
}

.dashboard-home-text {
  font-size: 24px;
  font-weight: 600;
}

.dashboard-page .side-navbar #home path,
.billing-page .side-navbar #billing path,
.review-page .side-navbar #database path {
  fill: #fff !important;
  /* color: #fff; */
}

.profile-page .side-navbar #profile path,
.search-page .side-navbar #search path {
  stroke: #fff;
}

.dashboard-page .side-navbar .HomeDashboard,
.profile-page .side-navbar .Profile,
.review-page .side-navbar .database,
.billing-page .side-navbar .Billing,
.search-page .side-navbar .Search,
.users-page .side-navbar .Users,
.dashboard-page .HomeDashboard,
.approve-page .companies,
.reports-page .reports,
.issue-page .Issue,
.companyreportsave-page .Reported,
.review-page .reviews,
.deletion-page .Deletion {
  border-radius: 40px;
  background: #2c4558;
  color: #fff;
}

.company-list{
  border-radius: 40px;
  background: #2c4558;
  color: #fff;
}

.side-navbar li a:hover,
.side-navbar li .drop-button:hover {
  border-radius: 40px;
  background: #2c4558;
  color: #fff;
}

.selection-review select:focus-visible {
  outline: none;
}

.selection-review {
  color: #647b8f;
  text-align: left;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.12px;
}

.selection-review select {
  color: #0976da;
  text-align: left;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.12px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selection-review svg {
  margin-top: 6px;
  margin-left: 4px;
}

.selection-review.selection-filter select {
  color: #647b8f;
}

.selection-filter select:focus-visible {
  outline: none;
}

.side-navbar li a {
  padding-left: 25px;
  color: #d3e1ec;
  text-decoration: none;
  display: flex;
  gap: 15px;
  height: 45px;
  align-items: center;
  padding: 0 25px;
}

/* dropdown */

.popup-form-dashboard {
  background: #fff;
  box-shadow: -5px 0px 20px 0px rgba(0, 0, 0, 0.15);
  /* padding-top: 48px; */
  padding-left: 0;
  padding-right: 0;
}

.popup-form.popup-form-dashboard input {
  border-radius: 6px;
  border: 1px solid #e7e8f2;
  background: rgba(255, 255, 255, 0.82);
}

.dark-mode-on {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 99;
}

.user-gray {
  color: var(--sj-boxtext, #647b8f);
  font-weight: 500;
}

.user-blue {
  color: var(--sj-blue, #0e2d41);
  font-weight: 500;
}

.reset-btn {
  border: 1px solid #d3e1ec;
  background: var(--sj-cancel, #f1f5f8);
}

.reset-btns {
  background: #0a1933;
}

.search-btn {
  padding-left: 2rem;
  padding-right: 2rem;
}

.approve-table td {
  color: var(--sj-blue, #0e2d41);
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
}

.searched-text {
  color: #547187;
  padding-bottom: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
}

.point-box {
  border-radius: 6px;
  border: 1px solid #d3e1ec;
  background: var(--sj-cancel, #f1f5f8);
  padding: 25px;
}

.point-box h5 {
  color: #547187;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
  padding-bottom: 12px;
}

.point-box h4 {
  color: #0d1021;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 76.923% */
  letter-spacing: 0.16px;
}

.upload-btn.height-42 {
  height: 42px;
}

.cancel-btn {
  width: 114px;
  height: 44px;
}

.save-btn {
  border-radius: 6px;
  background: var(--sj-blue, #0e2d41);
  box-shadow: 0px 0px 2px 0px rgba(21, 40, 80, 0.12),
    0px 2px 4px 0px rgba(21, 40, 80, 0.1);
  width: 114px;
}

.dark-color {
  background: #0e2d41 !important;
  color: #fff !important;
}

.light-color {
  background: var(--sj-box-gray, #f1f5f8);
  color: var(--sj-blue, #0e2d41);
}

.review-list {
  color: var(--sj-blue, #0e2d41);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.16px;
}

.border-bottom-row {
  border-bottom: 1px solid #e7e8f2;
}

/* check box */
.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #afb7bd;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.styled-checkbox+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  margin-top: -1px;
  background: white;
  border: 2px solid var(--sj-blue, #0e2d41);
}

.styled-checkbox:focus+label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
  border-radius: 4px;
  border: 2px solid var(--sj-blue, #0e2d41);
  background: var(--sj-blue, #0e2d41);
}

.styled-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.subscription-package {
  color: var(--sj-blue, #0e2d41);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.12px;
}

.pr-30 {
  padding-right: 120px;
}

.text-data {
  color: var(--sj-blue, #0e2d41);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.16px;
}

.submission-id {
  color: var(--sj-blue, #0e2d41);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.12px;
}

.view-btn {
  color: #0976da;
  border-bottom: 1px solid #0976da;
}

.prize-delete {
  padding-top: 12px;
  padding-bottom: 12px;
}

.detele-submit-checkbox .styled-checkbox+label:before {
  background: #f1f5f8;
  border: 1px solid #e7e8f2;
}

.detele-submit-checkbox .styled-checkbox:checked+label:before {
  border-radius: 4px;
  border: 2px solid #0e2d41;
  background: #0e2d41;
}

.detele-submit-checkbox {
  padding-left: 58px;
}

input:focus,
div:focus,
label:focus {
  outline: none !important;
}

.MuiInputBase-input {
  height: 48px;
}

.select-error .css-13cymwt-control {
  border: 1px solid #f44336;
  border-radius: 6px;
}

.error,
.login-form .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  margin-left: 14px;
  margin-right: 14px;
  font-family: Inter;
}

.signup-link {
  color: #1f82bf;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login .MuiInputAdornment-positionEnd {
  margin-left: -4rem !important;
}

.forgotlink {
  color: #647b8f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.account-para {
  color: #656565;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.forgot-password-section h1,
.login-section h1 {
  color: var(--sj-blue, #0e2d41);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 41.25px;
  margin-bottom: 25px;
}

.MuiOutlinedInput-inputAdornedEnd {
  padding-right: 14px !important;
}

/* .MuiInputBase-input {
  width: 92.3% !important;
} */

.signup-link {
  color: #1f82bf;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.signup .image-side img {
  object-fit: fill;
}

.signup-para {
  color: #656565;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 72px;
}

.signup .MuiOutlinedInput-input:-webkit-autofill {
  width: -webkit-fill-available !important;
}

.login .MuiOutlinedInput-input:-webkit-autofill {
  width: -webkit-fill-available !important;
}

.forgot-password-section .MuiOutlinedInput-input:-webkit-autofill {
  width: -webkit-fill-available !important;
}

.confirm-otp .MuiOutlinedInput-input:-webkit-autofill {
  width: -webkit-fill-available !important;
}

.signup .MuiInputAdornment-positionEnd {
  margin-left: 6rem !important;
}

.dashboard-page .container.px-5.mx-auto.pr-0 {
  margin-left: -1rem;
}

.dashboard-page .flex.flex-wrap.-m-2 {
  display: flex;
  flex-direction: row;
}

.dashboard-page .p-2.pl-4.w-1\/2.half-table {
  padding-left: 0.5rem;
}

.dashboard-page .verflow-x-auto.mr-10.sm\:rounded-lg {
  margin-right: 0px;
}

.footer-bg {
  background: var(--sj-blue, #0e2d41);
}

.footer-image {
  width: 80%;
}

#logo-sidebar {
  position: fixed;
}

img.sidebar-logo {
  background: #fff;
  display: flex;
  margin: 10px auto;
  padding: 5px;
  border-radius: 3px;
}

.ant-form.ant-form-vertical {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.ant-form.ant-form-vertical .formbody {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.ant-form.ant-form-vertical .ant-form-item {
  flex-basis: 48%;
  margin: 0;
}

input#creation::placeholder {
  color: #9ca3af;
  font-weight: 400;
}

.ant-tabs {
  display: block;
}

.ant-tabs-content {
  position: relative;
}

.section-head {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.12px;
  color: #0E2D41;

}

.billing-points {
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 45px;
  margin: 45px 0 0 0;
  justify-content: space-between;
}

.billing-points div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #0E2D41;

}

.billing-points div input {
  width: 70px;
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.16px;
  text-align: center;
  color: #0E2D41;

}

.section-head .right-head {
  display: flex;
  gap: 20px;
}

.section-head .right-head button {
  width: 100px;
  height: 42px;
  border-radius: 6px;
  border: 1px solid #D3E1EC;

}

.section-head .right-head button.btn-secondary {
  background: #F1F5F8;
}

/* .section-head .right-head button { 
    height: 30px;
    border: 1px solid #ccd7de;
    width: 130px;
    border-radius: 6px;
    padding: 0 12px;
    color: #647b8f;
    line-height: 30px; 
    font-size: 16px;
}  */

@media screen and (max-width: 1920px) {
  .second-judge-banner {}

  .signup .MuiInputAdornment-positionEnd {
    margin-left: -3rem !important;
  }

  .second-judge-banner-signup {
    height: 1040px;
  }
}

@media screen and (max-width: 1536px) {
  .second-judge-banner {
    height: auto;
  }

  .billing-card br {
    display: none;
  }

  .billing-points-btn {
    font-size: 11px;
  }

  .para {
    font-size: 13px;
  }

  .card-text {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .search-btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  /* .dash-nav-link {
    margin-top: 0 !important;
  } */

  .ex-employee-form {
    /* margin-left: 60px;
    margin-top: 800px;
    height: 1700px; */
  }

  .ex-employee-form.non-employee-form {
    /* height: 1450px;
    margin-top: 550px; */
  }

  /* .profile-dashboard{
        margin-left: ;
    } */
  .point-img {
    margin-top: -12px;
  }

  .forget-password-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .second-judge-banner-signup {
    height: 100%;
  }

  .pl-72 .ml-3 {
    margin-left: 0;
  }

  .pl-72 .pl-6 {
    padding-left: 1.2rem;
  }

  .profile-container {
    padding-left: 0;
  }

  .profile-container span {
    margin-left: 0;
  }

  .dashboard-profile .ml-4 {
    margin-left: 0;
  }

  .dashboard-profile {
    padding-left: 17.5rem;
  }

  .container.px-5.pl-0.mx-auto {
    padding-left: 1rem;
  }
}

.textarea-contactus {
  height: 122px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

/* 
.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  width: auto;
} */

.ant-layout {
  background-color: #fff;
}

.ant-spin-nested-loading .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  overflow: auto;
}

@media screen and (max-width: 1440px) {
  .image-side {
    width: 100%;
  }

  .form-side {
    width: 100%;
  }

  .image-side img {
    object-fit: inherit;
  }

  .signup .MuiInputAdornment-positionEnd {
    margin-left: -2rem !important;
  }

  .search-sect {
    border-radius: 0px;
  }

  .search-result {
    font-size: 20px;
  }
}

@media screen and (max-width: 1368px) {
  .target-achievement-dashboard {
    width: auto;
    font-size: 16px;
  }

  .point-history h4 {
    font-size: 20px;
  }

  /* .MuiOutlinedInput-input:-webkit-autofill {
    padding: 12px !important;
  } */

  /* .MuiInputLabel-outlined,
  .MuiInputLabel-outlined {
    line-height: 5px !important;
  } */

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 43px !important;
  }

  /* .MuiOutlinedInput-input,
  .MuiOutlinedInput-input {
    padding: 12px !important;
  } */

  .point-box h5 {
    font-size: 10px;
  }

  .point-box {
    padding: 15px;
  }

  .approve-page tr th,
  .approve-page tr td {
    padding-right: 0;
    font-size: 10px;
  }

  .approve-page tr th,
  .approve-page tr th {
    padding-right: 0;
    font-size: 10px;
  }

  .approve-padding {
    padding-left: 16.5rem;
  }

  .ex-employee-form {
    /* margin-left: 60px;
    margin-top: 950px;
    height: 1850px; */
  }

  .ex-employee-form.non-employee-form {
    /* height: 1550px;
    margin-top: 650px; */
  }

  .home-dashboard-padding {
    padding-left: 17.7rem;
  }

  .home-dashboard-margin {
    margin-left: 0;
  }

  .color2 {
    font-size: 14px;
  }

  .point-history {
    padding-bottom: 3.4rem;
  }
}

@media screen and (max-width: 1280px) {
  .signup .MuiInputAdornment-positionEnd {
    margin-left: -3rem !important;
  }

  .login .MuiInputAdornment-positionEnd {
    margin-left: -4rem !important;
  }

  /* .profile-section {
    margin-left: 14rem !important;
  } */

  .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 17rem;
  }

  .card-text {
    padding: 0px 0.5rem !important;
  }

  .para {
    padding-bottom: 14px;
  }

  .search-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: -1rem;
  }

  .search-page button.text-black.bg-white.border.border-gray-200.hover\:bg-white.focus\:ring-4.focus\:outline-none.focus\:ring-primary-300.font-medium.rounded-lg.text-sm.px-4.py-2\.5.text-center.inline-flex.items-center.dark\:bg-primary-600.dark\:hover\:bg-primary-700.dark\:focus\:ring-primary-800 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 13px;
  }

  .search-page table p,
  .search-page td,
  .search-table tr th,
  .searchname {
    padding-left: 0.5rem;
  }

  .search-page td,
  .search-page table p {
    font-size: 12px;
  }

  .search-sect {
    border-radius: 0px;
  }

  .login-form {
    padding: 130px 60px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .reports-page .more-filter-btn {
    width: 157px;
  }

  .newpassword .block.rounded-lg.bg-white.shadow-lg.dark\:bg-neutral-800 {
    padding-bottom: 3rem;
  }

  .reports-page input {
    font-size: 13px;
  }

  .billing-page .md\:grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .signup .MuiInputAdornment-positionEnd {
    margin-left: -3rem !important;
  }

  .login .MuiInputAdornment-positionEnd {
    margin-left: -4rem !important;
  }

  /* .profile-section {
    margin-left: 17rem !important;
  } */

  .padding-top-1 {
    padding: 3rem;
  }

  .billing-section {
    flex-wrap: wrap;
  }

  .max-w-sm.overflow-hidden.billing-card {
    max-width: 19rem;
  }

  .close-btn {
    display: none;
  }

  .billing-section-second {}

  .billing-section-second {}

  .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 12rem;
  }

  .billing-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 12rem;
  }

  /* .home-section {
    margin-left: 18rem !important;
  } */

  .dashboard-page .point-history {
    padding: 2rem;
  }

  .table-width {
    width: 20%;
  }

  .table-width input#first_name,
  .table-width input#last_name,
  .search-button {
    font-size: 15px;
  }

  .creation-detail,
  .company-detail,
  .creation-detail-list,
  .company-detail-list {
    display: none;
  }

  .search-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 0em;
  }
}

@media screen and (max-width: 991px) {
  /* .form-side {
    width: 100%;
  } */

  /* .image-side {
    width: 100%;
  } */

  .padding-top-1 {
    padding-top: 0rem;
  }

  .form-side {
    flex: 1 1;
    flex-basis: 100%;
  }

  .login-form {
    padding: 70px 30px 0 0px;
  }

  .image-side {
    padding: 70px 0 0 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .reports-page .search-margin {
    margin-right: 0.5;
  }
}

@media (max-width: 768px) {
  .panel-right .ant-layout-content {
    padding: 10px;
  }

  .home-section {
    padding-left: 0 !important;
    padding-right: 0;

  }

  .close-btn {
    display: block;
  }

  .dashboard-row {
    flex-direction: column;
    width: 98%;
  }

  .ant-layout-sider-children {
    position: fixed;
    background: #001529;
    padding: 0 5px;
  }

  .ant-layout .ant-layout-sider {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
    z-index: 9;
  }

  .ant-layout .ant-layout-sider.collapsed {
    width: 260px !important;
  }

  .profile-page .caption-top {
    flex-direction: column;
    gap: 20px;
  }

  /* CSS for screens 768px wide or narrower */
  .profile-page input::placeholder {
    font-size: 14px;
    /* Adjust the font size as needed */
  }

  .profile-txt {
    font-size: 20px;
  }

  .profile-page .edit-btn2 {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .dashboard-page .lg\:w-full.md\:w-2\/3.mx-auto {
    width: 100%;
  }

  /* .half-table {
    width: 100%;
  } */

  .flex.flex-wrap.-m-2 {
    display: flex;
    flex-direction: column;
  }

  .profile-page .edit-btn {
    border-radius: 6px;
    background: var(--sj-blue, #0e2d41);
    opacity: 1;
    font-size: 13px;
  }

  .billing-card .px-6.pt-1.pb-2 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }

  .billing-price {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .para {
    font-size: 12px;
    line-height: 16px;
  }

  .billing-card-icon {
    width: 30px !important;
    height: 31px !important;
    margin-bottom: 10px !important;
    margin-top: 4px !important;
  }

  .billing-card .px-6.py-2 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }

  .subscription-header th.px-6.py-3 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscription-header tr th {
    font-size: 11px;
  }

  .subscription-body td.px-6.py-4,
  .subscription-body th.px-6.py-4.font-medium.text-gray-900.whitespace-nowrap.dark\:text-white {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscription-body tr td,
  .subscription-body div {
    font-size: 11px;
  }

  /* img.second-judge-banner {
    display: none;
  } */

  .padding-top-1 {
    padding-top: 3rem;
    padding-bottom: 9rem;
  }

  .forgot-password-section .padding-top-1 {
    padding: 6rem;
    display: block;
  }

  .login .w-300.bg-white.rounded-lg.dark\:border.md\:mt-0.sm\:max-w-md.xl\:p-1.w-100.dark\:bg-white.dark\:border-white {
    max-width: 100%;
  }

  .login .account-para {
    display: flex;
    justify-content: center;
  }

  /* 
  .image-side {
    display: none;
  } */

  .login .MuiInputAdornment-positionEnd {
    margin-left: -4rem !important;
  }

  .profile-section {
    margin-left: 1rem !important;
    margin-right: 1rem;
  }

  .flex-profile {
    display: flex;
    flex-direction: row !important;
  }

  .dashboard-profile {
    padding-left: 9.5rem;
  }

  .padding-top-1 {
    padding: 8rem;
  }

  /* 
  .billing-section {
    margin-left: 7rem !important;
  } */

  /* .billing-section-second {
    margin-left: 7rem !important;
    width: 31rem !important;
  } */

  .billing-section-second-right {
    /* margin-right: 1rem; */
    overflow: auto;
  }

  .billing-right {
    margin-right: 1rem;
  }

  .billing-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 16rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .target-achievement-dashboard {
    width: auto;
    font-size: 14px;
  }

  .dashboard-page .image-side-table .point-history {
    padding: 1rem;
  }

  .dashboard-page .point-history h4 {
    font-size: 16px;
  }

  .dashboard-page caption {
    font-size: 14px;
  }

  /* .home-section {
    margin-left: 17rem !important;
  } */

  /* 
  .heading-home {
    padding-left: 16rem;
  } */

  .login-form {
    padding: 40px 40px;
  }

  .dashboard-page .non-ex-employee {
    padding-left: 16rem;
  }

  .dashboard-page .flex.flex-wrap.-m-2 {
    display: flex;
    flex-direction: column;
  }

  .dashboard-page .half-table {
    width: 100%;
  }

  .dashboard-page .login-section {
    gap: 20px;
  }

  .dashboard-page .form-side,
  .dashboard-page .image-side {
    width: 100%;
    padding-right: 0;
  }

  .profile-page .p-2.w-1\/2.pr-4.pb-4.input-width,
  .profile-page .p-2.w-1\/2.pl-4.pb-4.input-width {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  /* .profile-section {
    margin-left: 12rem !important;
  } */

  .search-page table p,
  .search-page td {
    font-size: 8px;
  }

  /* .search-section-table {
    margin-left: 17rem !important;
  } */

  .search-table tr th {
    font-size: 10px;
  }

  .search-page th {
    padding-right: 0rem;
    padding-left: 7px;
  }

  .user-image {
    width: 20px;
    height: 20px;
  }

  .search-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 0;
  }

  .table-width input#first_name,
  .table-width input#last_name {
    font-size: 8px;
  }

  /* .search-right {
    margin-right: 1rem;
  } */

  button.text-white.bg-gray-800.border.border-gray-200.focus\:ring-4.focus\:outline-none.font-medium.rounded-lg.text-sm.px-4.py-2\.5.text-center.inline-flex.items-center.dark\:bg-primary-600.dark\:hover\:bg-primary-700.dark\:focus\:ring-primary-800 {
    font-size: 8px;
  }

  .pr-0.search-button button {
    font-size: 8px;
  }

  .search-page svg#filter {
    width: 10px;
  }

  .table-width,
  .searchbutton {
    padding-right: 6px;
  }

  .search-sect {
    margin-right: 1rem;
  }

  .image-side {
    justify-content: flex-start;
  }
}

/* @media (max-width: 767px) {
  .billing-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center p.font-md {
    margin-left: 0rem !important;
  }
} */

@media screen and (max-width: 767px) {
  .employee-details .form-data {
    flex-basis: 100%;
  }

  .employee-details .form-head {
    flex-direction: column;
    gap: 20px;
  }

  .approve-padding {
    padding-left: 0;
  }

  .search-flex {
    display: block;
    margin: 0 20px;
  }

  .search-flex .w-1\/4 {
    width: 100%;
    margin-bottom: 15px;
  }

  .search-flex .search-btn,
  .filter-btn {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .search-flex .search-btn {
    margin-bottom: 15px;
  }

  /* .approve-table-div {
    display: none;
  } */

  .approve-next-div {
    align-items: end;
    margin-right: 20px;
  }

  .billing-margin-div {
    margin: 0 20px;
  }

  .search-margin {
    margin: 0;
  }

  .w-auto {
    margin-left: 0 !important;
  }

  .flex.gap-8.mr-10 {
    display: block;
    margin: 0 2rem;
  }

  .flex.gap-8.mr-10 .w-1\/4 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .flex.flex-wrap.-m-2 {
    display: flex;
  }

  .p-2.pb-4.pt-4.w-1\/2 {
    width: 100%;
  }

  table.w-full.text-sm.text-left.text-gray-500.dark\:text-gray-400.mt-10 .mr-10 {
    margin: 0 2rem;
  }

  nav.md\:ml-auto.flex.flex-wrap.items-center.text-base.justify-center.pr-5 {
    justify-content: left;
    gap: 1rem;
  }


  .login-section {
    flex-direction: column;
  }

  .MuiInputBase-input {
    width: 94.3% !important;
  }
}

@media (max-width: 560px) {
  .billing-section {
    flex-direction: column;
  }

  .forgot-password-section {
    flex-direction: column;
  }

  .MuiInputBase-input {
    width: 94.5% !important;
  }

  .login-form {
    padding: 40px 15px;
  }
}

@media (max-width: 480px) {
  .MuiInputBase-input {
    width: 92% !important;
  }

  .forgot-password-section .padding-top-1 {
    padding: 2rem;
    padding-bottom: 10rem;
  }

  .flex-profile {
    display: flex;
    flex-direction: column !important;
  }

  .input-width {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .profile-section {
    margin-left: 0.5rem !important;
  }

  .padding-top-1 {
    padding: 0px;
  }

  .confirm-otp .form-side {
    width: 100%;
    /* padding-bottom: 10rem; */
  }

  .newpassword .MuiInputAdornment-positionEnd {
    margin-left: 6rem !important;
  }

  .padding-top-1 {
    padding-top: 3rem;
    display: block;
    padding-bottom: 12rem;
  }

  .forgot-password-section h1 {
    font-size: 25px;
  }

  .signup .MuiInputAdornment-positionEnd {
    margin-left: 6rem !important;
  }

  .login .MuiInputAdornment-positionEnd {
    margin-left: 5rem !important;
  }

  .profile-page .profile-container {
    display: flex;
    padding-left: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .subscription-header tr th {
    font-size: 10px;
  }

  .billing-section-second {
    margin-left: 0.3rem !important;
    width: auto !important;
  }

  .billing-section {
    margin-left: 4px !important;
  }

  .billing-right,
  .billing-section-second-right {
    margin-right: 0.5rem;
  }

  .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center p.font-md {
    margin-left: -10rem;
  } */

  .billing-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    margin-left: 0rem;
  }

  .dashboard-page .half-table {
    width: 100%;
    padding-right: 14px;
  }

  .dashboard-page .container.px-5.mx-auto.pr-0 {
    margin-left: -7px;
  }

  .dashboard-page .form-side,
  .dashboard-page .image-side {
    width: 100%;
  }

  .heading-home {
    padding-left: 0rem;
    align-items: flex-start;
  }

  .employee-toggle {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: flex-start;
  }

  .employee-toggle button {
    font-size: 15px;
  }

  .dashboard-page .non-ex-employee {
    padding-left: 0rem;
  }

  .dashboard-page .add-review-btn {
    height: 39px;
  }

  .review-btn {
    padding-right: 0px;
  }

  /* .dashboard-page .form-side {
    padding-right: 17px;
  } */

  .dashboard-page .image-side {
    padding-right: 17px;
    padding-left: 0.5rem;
  }

  .dashboard-page .container1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .sidebar-logo img {
    height: 40px;
    width: 40px;
  }

  .search-table-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .search-table-section .form-fields {
    width: 100%;
    font-size: 10px;
  }

  button.more-filter-btn {
    font-size: 14px;
  }

  .table-width {
    width: 100%;
    padding-bottom: 10px;
  }

  button.text-white.bg-gray-800.border.border-gray-200.focus\:ring-4.focus\:outline-none.font-medium.rounded-lg.text-sm.px-4.py-2\.5.text-center.inline-flex.items-center.dark\:bg-primary-600.dark\:hover\:bg-primary-700.dark\:focus\:ring-primary-800 {
    font-size: 11px;
    width: 150px;
    justify-content: center;
  }

  .pr-0.search-button button {
    font-size: 13px !important;
  }

  .table-width input#first_name,
  .table-width input#last_name {
    font-size: 10px;
  }

  .pr-0.search-button button {
    padding-left: 4px;
    padding-right: 4px;
  }

  .search-right {
    margin-right: 0rem;
  }

  .search-page p.font-md {
    margin-left: -10rem;
  }

  .dash-nav-link {
    margin-top: 6px !important;
  }
}

@media (max-width: 426px) {

  .search-page table p,
  .search-page td {
    padding-left: 0rem;
    padding-right: 1rem;
  }

  .search-sect {
    margin-right: 0rem;
  }

  .search-section-table {
    margin-left: 6px !important;
  }

  .search-sect {
    margin-right: 8px;
  }
}

@media (max-width: 425px) {
  .container1 {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .p-2.pr-4.w-1\/2.half-table {
    width: 100% !important;
  }

  .container .w-1\/2 {
    width: 100% !important;
    margin-right: 0rem !important;
  }

  .container .w-1\/2 {
    width: 100%;
    margin-right: 0rem;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dashboard-layout .home-section {
    padding-left: 0 !important;
    padding-right: 0;
    width: auto;
    overflow-x: scroll;
    scrollbar-width: thin;
    border: 1px solid #E7E8F2;
  }
}

@media (max-width: 380px) {
  .newpassword .MuiInputAdornment-positionEnd {
    margin-left: 3rem !important;
  }

  .signup .MuiInputAdornment-positionEnd {
    margin-left: 3rem !important;
  }

  .login .MuiInputAdornment-positionEnd {
    margin-left: 2rem !important;
  }

  .subscription-header tr th {
    font-size: 8px;
  }

  .subscription-body tr td,
  .subscription-body div {
    font-size: 9px;
  }

  .para {
    font-size: 10px;
    line-height: 16px;
  }

  .billing-card {
    padding: 0px;
  }

  .billing-buy-btn {
    font-size: 12px !important;
  }

  .employee-toggle button {
    font-size: 11px;
  }

  .employee-toggle button {
    height: 36px;
    align-items: center;
    display: flex;
  }

  .dashboard-page .add-review-btn {
    height: 36px;
  }

  .home-section th,
  .home-section p,
  .home-section td {
    font-size: 11px;
  }

  .dashboard-page .point-history h4 {
    font-size: 13px;
  }

  .point-history h5 {
    font-size: 12px;
  }

  .pr-0.search-button button {
    font-size: 11px;
  }

  .table-width input#first_name,
  .table-width input#last_name {
    font-size: 12px;
  }

  .search-page table p,
  .search-page td {
    padding-left: 4px;
    padding-right: 10px;
  }

  .search-sect {
    margin-right: 0rem;
  }

  .search-page .container1.mx-auto.flex.flex-wrap.p-5.flex-col.md\:flex-row.items-center {
    padding: 6px;
  }

  .search-result {
    font-size: 20px;
  }

  .search-page button.bg-gray-800.hover\:.text-white.font-base.py-2.px-4.rounded {
    font-size: 13px;
  }

  .w-auto.search-section-table {
    margin-left: 0px !important;
  }

  .search-page p.font-md {
    margin-left: -8rem;
  }

  .search-result {
    font-size: 16px;
  }

  .search-sect span {
    font-size: 14px;
  }

  .pr-0.search-button button {
    font-size: 10px !important;
  }
}


.invalid{
  opacity: 0.3;
  cursor: not-allowed;
}

.notallowUpload{
  cursor: default;
}
