.table-container {
  overflow-x: auto;
}

.table {
  table-layout: fixed;
  width: 100%;
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.long-text {
  word-break: break-all; /* or word-break: break-word; */
}

/* Example CSS to ensure stability */

