#default-sidebar {
    background: color gray -900px;
    ;
}

h1.title-font {
    font-size: 73px;
    font-weight: 600;
    line-height: 89px;
    letter-spacing: 0.01em;
    color: #0E2D41;

}

.banner-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.hrLine {
    color: rgb(18, 12, 12);
    font-size: small;
}

.background {
    background-color: blue;
    border-radius: 50%;

}

svg {
    color: white
}



.billing-card-icon {
    width: 43px;
    height: 43px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.billing-card {
    border-radius: 6.036px;
    background: var(--sj-box-gray, #F1F5F8);
}

.billing-points-btn {
    border-radius: 6px;
    border: 1px solid var(--sj-menu-name, #D3E1EC);
    background: rgba(9, 118, 218, 0.10);
}

.billing-buy-btn {
    border-radius: 6px;
    background: var(--sj-blue, #0E2D41);
    color: white;
}

.billing-price {
    color: var(--sj-helpingblue, #0976DA);
}

.color {
    color: red;
    font-size: 16px;
    margin-top: 0 !important;
}

.color2 {
    color: red;
    font-size: 15px;
    margin-top: -16px !important;
}

.nonjoiner {
    display: none;
}

.Product-thumbnail {
    /* box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.13); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    cursor: pointer;
    border: 1px solid #CCD7DE;
    border-radius: 6px;
    margin-bottom: 30px;
    transition: all ease-in-out 0.5s;
}
.Product-thumbnail:hover {
    box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.13); 
}

.Product-thumbnail img {
    object-fit: cover;
    height: 100%;
}

.require {
    color: #FF0000;
    position: relative;
    left: 100%;
    top: 10px;
}


.notallowonpic{
    cursor: default;
}